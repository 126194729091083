import { useFeature } from "featurehub-react-sdk";

export function Component() {
  
  const testFeature = useFeature("test")
  const text = `Test Feature: ${testFeature}`

  return (
    <p>
      {text}
    </p>
  );
}